<template lang="pug">
  section#competence
    .container.mx-auto
      h1 Kompetens
      .flex(class="flex-col lg:flex-row")
        .text-container.flex-1.mr-8
            p Under mina över 20 år som utvecklare har jag utvecklat en stadig grund inom programmering som helhet och kommit i kontakt med en mängd olika språk. Jag är väl bevandrad i att använda olika webb-tekniker, HTML5, JavaScript, responsiv design med CSS3 och flexbox.
        .flex-1.mt-5.px-4(class="lg:mt-0 lg:px-0")
          ProgressBar(label="HTML & CSS", :value="0.95")
          ProgressBar(label="JavaScript", :value="0.95")
          ProgressBar(label="Vue.js", :value="0.8")
          ProgressBar(label="Angular", :value="0.7")
          ProgressBar(label="Golang", :value="0.7")
          ProgressBar(label="Design", :value="0.3")

</template>

<script>
import ProgressBar from './ProgressBar.vue'

export default {
  name: 'Competence',
  components: {
    ProgressBar
  }
}
</script>

<style lang="scss">
@import "../assets/css/_variables.scss";

#competence {
  .text-container {
    flex: 1;
    background: $primary-bg;
    padding: 2rem 2rem;
    width: 100%;
    float: right;
    margin-top: 0rem;
  }

  .bar-element:first-child {
    padding-top: 0;
  }
}

</style>
