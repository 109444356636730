<template lang="pug">
  section#hero.w-screen.h-screen.text-white.fixed.top-0
    .text-container.text-center
      h2.text-4xl.font-bold(class="lg:text-6xl") Nytt perspektiv!
      span.text-xl.font-bold(class="lg:text-2xl") Webben rör sig framåt, gör du?
    //.w-full
</template>

<script>
export default {
  name: 'Hero',
  components: {

  }
}
</script>

<style lang="scss">
@import "../assets/css/_variables.scss";

#hero {
  background: url("../assets/hero.jpg");
  background-size: auto 150vh;
  background-position: center -15vh;
  z-index: -1;

  .text-container {
    /* margin: 20rem auto; */
    transform: translateY(-50%);
    top: 30%;
    position: relative;
  }
}
</style>
