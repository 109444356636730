<template lang="pug">
  footer.text-white.align-middle.text-center.font-bold
    span Innehar F-skattesedel
</template>

<script>
export default {
  name: 'App-Footer',
}
</script>

<style lang="scss">
footer {
  background: #2C2C2C;
  height: 30px;
  line-height: 30px;
}
</style>
