<template lang="pug">
  section#introduction
    .container.mx-auto.clearfix.relative
      h1.hidden Introduktion
      .headshot-container
      .text-container
        h2.text-4xl.tracking-widest Hej!
        p Jag har arbetat som systemutvecklare sedan 2009 med en gedigen bakgrund som hobbyutvecklare under större delen av mitt liv och en utbildning som spelprogrammerare. Under 2010-2017 arbetade jag som IT-konsult hos Sogeti, med inriktning på .NET, EPiServer och responsiv webbdesign. Mina specialområden är bl.a. JavaScript och CSS. Jag har arbetat med hela stacken inom webbutveckling.
        p Sen 2018 arbetar jag som frilansande it-konsult vid sidan av min anställning som professionell jonglör.
</template>

<script>
export default {
  name: 'Competence',
  components: {

  }
}
</script>

<style lang="scss">
@import "../assets/css/_variables.scss";

#introduction {
  .headshot-container {
    background: url("../assets/headshot4.jpg");
    width: 200px;
    height: 200px;
    border: 15px solid $primary-bg;
    /* top: -100px; */
    top: 0px;
    @apply overflow-hidden bg-cover absolute rounded-full;
  }

  .text-container {
    @apply float-right py-8 px-8 w-full;
    background: $primary-bg;
    margin-top: 7rem;

    h2 {
      @apply ml-40 mb-4;
    }
  }

  /* Small (sm) */
  @media (min-width: 640px) { /* ... */ }

  /* Medium (md) */
  @media (min-width: 768px) { /* ... */ }

  /* Large (lg) */
  @media (min-width: 1024px) {
    .headshot-container {
      width: 288px;
      height: 288px;
      top: 0;
    }

    .text-container {
      @apply float-right py-8 px-40;
      width: 87%;
      margin-top: 7rem;

      h2 {
        @apply ml-0 mb-2;
      }
    }
  }

  /* Extra Large (xl) */
  @media (min-width: 1280px) { /* ... */ }

  h2 {
    font-family: 'Shadows Into Light', cursive;
  }

}
</style>
