<template lang="pug">
  .swoosh(:class="{overlap: overlap}")
    svg.w-full(height="100%" viewBox="0 0 600 80" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none")
      path(d="M600 0v80H0v-1.54C23.59 49.08 561.72 55.62 600 0z" :fill="color")
</template>

<script>
export default {
  name: 'Swoosh',

  props: {
    color: String,
    overlap: Boolean,
  },
}
</script>

<style lang="scss">
  .swoosh {
    @apply w-full sticky pointer-events-none;
    height: 50px;
    top: 32px;
    z-index: 14;
    margin-bottom: -1px;

    &.overlap {
      margin-top: -50px;
    }

    @screen lg {
      height: 80px;
      top: 32px;

      &.overlap {
        margin-top: -80px;
      }
    }
  }
</style>
