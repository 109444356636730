import Vue from 'vue'

// import 'bootstrap'
// import 'bootstrap/dist/css/bootstrap.min.css'
import VueToastr2 from 'vue-toastr-2'
import 'vue-toastr-2/dist/vue-toastr-2.min.css'
import 'axios'
import '@/assets/css/tailwind.css'

window.toastr = require('toastr')

Vue.use(VueToastr2)

import App from './App.vue'

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
