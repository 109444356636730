<template lang="pug">
  nav#mobile-menu.slide-menu
    .controls.text-right
      button(type="button" class="btn slide-menu__control" data-action="close")
        i.fas.fa-times.text-white.p-4.text-2xl
    ul
      li
        a(href='#services', title="Tjänster") Tjänster
      li
        a(href='#introduction', title="Introduktion") Introduktion
      li
        a(href='#competence', title="Kompetens") Kompetens
      li
        a(href='#contact', title="Kontakt") Kontakt

  //nav.h-screen.fixed.inset-y-0.right-0.z-20
    ul.list-none.text-white.text-2xl.font-bold
      li Tjänster
      li Introduktion
      li Kompetens
      li Kontakta
</template>

<script>
import '@grubersjoe/slide-menu/dist/slide-menu.js'
import '@grubersjoe/slide-menu/dist/slide-menu.css'

export default {
  name: 'MobileMenu',
  components: {

  },

  data() {
    return {
      mobileMenu: null
    }
  },

  mounted() {
    document.addEventListener("DOMContentLoaded", () => {
      const menuElement = document.getElementById('mobile-menu');
      this.mobileMenu = new SlideMenu(menuElement);
      // const menu = new SlideMenu(menuElement);
    });

    let menuItems = document.querySelectorAll("#mobile-menu a")
    menuItems.forEach(item => {
      item.addEventListener("click", (e) => {
        e.preventDefault()

        let element = document.querySelector(e.target.hash)
        if (element == null) {
          window.scrollTo({top: 0, behavior: 'smooth'})
        } else {
          let scrollPos = element.offsetTop - 111
          window.scrollTo({ top: scrollPos, behavior: 'smooth'})
        }
        this.mobileMenu.close()
      })
    })

  },
}
</script>

<style lang="scss">
@import "../assets/css/_variables.scss";

.controls {
  background: #172531;
}

.slide-menu {
  background: #333b4a;
  box-shadow: rgba(0, 0, 0, 0.5) 4px 10px 10px 5px;

  li {
    @apply w-full text-white font-bold text-lg border-b border-black;

    a {
      @apply pl-4 py-4;
    }
  }

}

</style>
