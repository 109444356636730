<template lang="pug">
  .bar-element
    span {{ label }}
    .bar-container
      .bar-progress(:style="'width:' + (100 * value) + '%'")
</template>

<script>
export default {
  name: 'ProgressBar',
  props: {
    label: {
      type: String,
      required: true
    },
    value: {
      type: Number,
      required: true
    }
  }
}

</script>

<style lang="scss">
@import "../assets/css/_variables.scss";

.bar-element {
  padding: 0.5rem 0 0.2rem;
  width: 100%;

  span {
    display: inline-block;
    padding-left: 0.3rem;
    padding-bottom: 0.2rem;
  }
}

.bar-container {
  background: #E8E8E8;
  overflow: hidden;
  border-radius: 6px;
  height: 11px;
  width: 100%;
}

.bar-progress {
  background: $second-color;
  border-radius: 6px;
  height: 100%;
  width: 95%;
}
</style>
