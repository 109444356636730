<template lang="pug">
  nav.desktop-nav
    .bg-small
    // .bg
    .container.mx-auto
      .flex.px-2
        a.logoText.ml-2.relative(class="lg:flex-1", href="#", title="Topp")
          h1.m-0.font-bold.text-2xl(class="lg:text-3xl") Svart Adam Solander
          span.-m-1.block.text-xl.italic.leading-none(class="lg:text-2xl") frilansande it-konsult
        .flex-1.relative(class="text-right lg:text-left")
          button.mobile-menu.slide-menu__control(class="lg:hidden text-2xl" type="button" data-target="mobile-menu" data-action="open")
            i.fas.fa-bars.p-4

          .menu.float-right(class="hidden lg:block")
            ul.m-0.p-0.list-none
              li
                a.p-4(href="#services", title="Tjänster") Tjänster
              li
                a.p-4(href="#introduction", title="Introduktion") Introduktion
              li
                a.p-4(href="#competence", title="Kompetens") Kompetens
              li
                a.p-4(href="#contact", title="Kontakt") Kontakt
</template>

<script>
export default {
  name: 'App-Header',
  components: {

  },

  mounted() {
    let menuItems = document.querySelectorAll(".menu a, .logoText")
    menuItems.forEach(item => {
      item.addEventListener("click", (e) => {
        e.preventDefault()

        let element = document.querySelector(e.target.hash)
        if (element == null) {
          window.scrollTo({top: 0, behavior: 'smooth'})
        } else {
          let scrollPos = element.offsetTop - 111
          window.scrollTo({ top: scrollPos, behavior: 'smooth'})
          // element.scrollIntoView({ behavior: 'smooth'})
        }
      })
    })
  },
}
</script>

<style lang="scss">
.desktop-nav {
  @apply text-white fixed overflow-hidden inset-x-0 top-0; // -mx-4 py-1 px-12;

  height: 82px;
  z-index: 13;

  @screen lg {
    height: 110px;
  }

  .menu {
    position: absolute;
    top: 50%; left: 50%;
    transform: translate(-50%,-50%);

    li {
      @apply inline font-bold text-lg;
    }
  }

  .bg-small {
    width: 100vw;
    position: absolute;
    top: 0;
    height: 111px;
    left: 0;
    background: url("../assets/hero.jpg");
    /* background: url(/img/hero.1e9c821d.jpg); */
    background-position: center -15vh;
    background-size: auto 150vh;
  }

  .bg {
    position: absolute;
    top: -30px;
    left: 0;
    right: 0;
    bottom: 35px;
    //margin: -10px auto auto -10px;
    background: rgba(56, 56, 56, 0.46);
    filter: blur(15px);
  }
}
</style>
