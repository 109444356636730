<template lang="pug">
  section#services
    .container.mx-auto
      h1 Tjänster
      .flex(class="flex-col lg:flex-row")
        .service(class="mb-4 lg:mr-8 lg:mb-0")
          h2.uppercase Front-end
          p Jag utvecklar bl.a. fungerande webbplatser med HTML5 och CSS3 utifrån designskisser och skapa dynamiska kundspecifika lösningar med moderna webbtekniker.
          img(src="img/FontAwesome/chrome-brands.svg", alt="Chrome brands").icon
        .service(class="mb-4 lg:mr-8 lg:mb-0")
          h2.uppercase Back-end
          p Jag erbjuder kompetens inom olika ramverk för back-end, inkluderande .NET, Golang, Node.js och kan bygga speciallösningar som passar mina kunders behov.
          img(src="img/FontAwesome/cogs-solid.svg", alt="Cogs image").icon
        .service
          h2.uppercase Hosting
          p Med en bred kunskap inom Linux, både som server och arbetsstation, så kan jag erbjuda mina kunder att ta hand om deras hosting. Antigen på en delad VPS eller en dedikerad för just erat projekt.
          img(src="img/FontAwesome/database-solid.svg", alt="Database image").icon
</template>

<script>
export default {
  name: 'Services',
  components: {

  }
}
</script>

<style lang="scss">
@import "../assets/css/_variables.scss";

#services {
  .service {
    @apply flex-1 py-8 px-6 rounded;
    padding-bottom: 5rem;
    background: $primary-bg;
    position: relative;
  }

  .icon {
    /* font-size: 4.5rem; */
    /* color: rgba(0,0,0,0.05); */
    position: absolute;
    right: 1rem;
    bottom: 1rem;
    /* float: right; */
    height: 89px;
    opacity: 0.05;

    &.hosting {
      height: 78px;
    }
  }
}
</style>
