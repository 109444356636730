<template lang="pug">
#app.relative
  MobileMenu
  AppHeader

  Hero

  Swoosh(color="#ffffff")
  .content.bg-white
    Fader(color="#ffffff")
    //.blurElement.w-full(:style="'background-image: linear-gradient(to bottom, rgb(255,255,255), rgba(255, 255, 255, 0.0));'")
    Services.py-16

    Introduction.py-16

    Competence.py-16

  //Swoosh(color="#ffa500", :overlap="true")
  //div.w-full(style="height: 400px; background: #ffa500")

  Swoosh(color="#566279", :overlap="true")
  .content(style="background: #566279;")
    Fader(color="#566279")
    //.blurElement.w-full(:style="'background-image: linear-gradient(#566279, rgba(0, 0, 0, 0));'")

    Contact

  Footer

</template>

<script>
import AppHeader from './components/Header.vue'
import MobileMenu from './components/MobileMenu.vue'
import Hero from './components/Hero.vue'
import Services from './components/Services.vue'
import Introduction from './components/Introduction.vue'
import Competence from './components/Competence.vue'
import Contact from './components/Contact.vue'
import Footer from './components/Footer.vue'
import Swoosh from './components/Swoosh.vue'
import Fader from './components/Fader.vue'

export default {
  name: 'app',
  components: {
    AppHeader,
    MobileMenu,
    Hero,
    Services,
    Introduction,
    Competence,
    Contact,
    Footer,
    Swoosh,
    Fader,
  }
}
</script>

<style lang="scss">
@import "assets/css/_variables.scss";

p {
  padding-bottom: 1rem;
}

body {
  overflow-x: hidden;
}

#app {
  font-family: Montserrat, sans-serif;
  padding-top: 94vh;
  // TODO: Switch to this method when Edge supports it.
  // scroll-behavior: smooth;
}

.blurElement {
  height: 40px;
  width: 100%;
  position: sticky;
  top: 82px;
  z-index: 1;

  @screen lg {
    top: 112px;
  }
}

#services, #introduction, #competence {
  @apply px-4 bg-white;

  h1 {
    @apply uppercase text-center text-2xl mb-4 tracking-widest;
  }
}

#competence {
  @apply mb-0 pb-32;
}

#services {
  @apply mt-0;
}


</style>
