<template lang="pug">
  .fadeElement.w-full(:style="getLinearGradient()")
</template>

<script>
export default {
  name: 'Fader',

  components: {
  },

  props: {
    color: String
  },

  methods: {
    transparentColor: function () {
      let hexColor = this.color.replace('#', '')
      let r = parseInt(hexColor.substring(0, 2), 16)
      let g = parseInt(hexColor.substring(2, 4), 16)
      let b = parseInt(hexColor.substring(4, 6), 16)
      let result = 'rgba(' + r + ',' + g + ',' + b + ',0)'
      return result
    },

    getLinearGradient: function () {
      return `
        background: -moz-linear-gradient(${this.color}, ${this.transparentColor()}));
        background: -webkit-gradient(${this.color}, ${this.transparentColor()}));
        background: -webkit-linear-gradient(${this.color}, ${this.transparentColor()});
        background: -o-linear-gradient(${this.color}, ${this.transparentColor()});
        background: -ms-linear-gradient(${this.color}, ${this.transparentColor()});
        background: linear-gradient(${this.color}, ${this.transparentColor()});
      `
    }
  }


}
</script>

<style lang="scss">
.fadeElement {
  height: 40px;
  width: 100%;
  position: sticky;
  top: 81px;
  z-index: 1;

  @screen lg {
    top: 112px;
  }
}

</style>
