<template lang="pug">
section#contact.text-white.relative.text-center.px-4.pt-6.pb-4(class="md:pb-12")
  .w-full
    h1.text-center.uppercase.pb-3.text-5xl Kontakt
    span.block.pb-2.text-xl.font-bold Vill ni komma i kontakt med mig?
    span.block.text-base Fyll i formuläret nedan, så hör jag av mig!

    .container.mx-auto.mt-8.mb-0(class="md:mb-12")
      .flex.flex-col(class="md:flex-row")
        .flex-1.mb-3(class="md:mr-4 md:mb-0")
          label.hidden(for="txtName") Name
          input#txtName.input-box(type="text", placeholder="Namn", v-model="formData.name")
          input.hidden(type="text", placeholder="Företag", v-model="formData.company")
          label.hidden(for="txtEmail") Email
          input#txtEmail.input-box(type="text", placeholder="E-post", v-model="formData.email")
          label.hidden(for="txtPhone") Phone
          input#txtPhone.input-box(type="text", placeholder="Telefonnummer", v-model="formData.phone")
        .flex-1
          label.hidden(for="txtMessage") Message
          textarea#txtMessage.input-box.h-32(class="md:h-full", placeholder="Meddelande", v-model="formData.msg")
          button.btn.btn-primary(@click="submit", aria-label="Skicka meddelande") Skicka meddelande
</template>

<script>
import axios from 'axios';

export default {
  name: 'Contact',
  components: {

  },
  data() {
    return {
      formData: {
        name: "",
        email: "",
        phone: "",
        company: "",
        msg: ""
      },
      captcha: {
        key: "",
        value: "",
        img: "",
      }
    }
  },
  methods: {
    submit: function() {
      axios.post("/api/contact/", {
        name: this.formData.name,
        email: this.formData.email,
        phone: this.formData.phone,
        company: this.formData.company,
        msg: this.formData.msg,
      }).then(result => {
        console.log(result)
      })
    },

    successfulSubmit: function() {

    },

    clearForm: function() {
      this.formData = {
        name: "",
        email: "",
        phone: "",
        company: "",
        msg: "",
      }
    }

  }
}
</script>

<style lang="scss">
@import "../assets/css/_variables.scss";

#contact {
  margin-top: -1px;
  background: #566279;
  /* z-index: 12; */

  h1 {
    font-family: 'Shadows Into Light', cursive;
  }

  // .container {
  //   margin-top: 2rem;
  //   margin-bottom: 3rem;
  // }

  .btn-primary {
    background: #B6AB7B;
    padding: 0.5rem 1.5rem;
    font-weight: bold;
    font-size: 0.9rem;
    float: right;
    border: 1px solid rgba(0, 0, 0, 0.3);
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  }

  .input-box {
    @apply block w-full text-base font-normal leading-normal bg-white rounded mb-3 px-3;

    @screen md {
      @apply mb-4
    }

    padding-top: .375rem;
    padding-bottom: .375rem;
    color: #495057;
    border: 1px solid #ced4da;
    /* -webkit-transition: border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out; */
    /* transition: border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out; */
    /* transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out; */
    /* transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out; */

    background: #E9E9E9;

    &:last-child {
      margin-bottom: 0;
    }
  }

  textarea.input-box {
  }

}
</style>
